import { loadMap } from "./loadMap";
import  jsonPath  from "../../stationsAll.json";

// получаем данные пользователся из формы и записываем их в массив
export async function getData(idCity=1002){
        const res = await fetch(jsonPath, {
        });
    const data = await res.json();
    // console.log( data );
    // console.log( idCity );
// получаем массив из линий метрополитена города
    let arrLines = [...data]
    .map(  function (items) {
           
                if( items.id == idCity ){
                   
                return items.lines;
            }   else {
                return false;
            }    
    })
    .filter(function (item) {
        // console.log(item);
        if (item !== false) {
            return item;
        }
    });


// console.log(arrLines);


    // получаем координаты города по централной станции 1 ветки метрополитена
    // console.log(arrLines);
    let centrIndexStations = Math.floor ((arrLines[0][0].stations.length)/2);
    let centralStations = arrLines[0][0].stations[centrIndexStations];
    let coord = [centralStations.lat, centralStations.lng];
    
 
//   //загрузка карты 
    loadMap(arrLines, coord);
    
    return arrLines;
}

   

  