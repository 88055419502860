  const chooseFirst = document.getElementById('chooseFirst');
    // console.log(chooseFirst);
  const chooseSecond = document.getElementById('chooseSecond');
    // console.log(chooseSecond);

  function fillSelectStation(arrStations){
    // console.log(arrStations);
    let arrOptions=[];
    return arrStations
    .then((dataStations)=>{
        let stations = dataStations[0];
        // console.info(stations);
        const fragment = document.createDocumentFragment();
        const fragment2 = document.createDocumentFragment();
        stations.map((items)=>{
          // console.log(items);
            for(const {name, id} of items.stations){
              // console.log({name, id});
              arrOptions.push({name, id});
            } 
        });
        // сортировка по имени
         arrOptions.sort(function(a, b) {
            if (a.name > b.name) {
              return 1; }
            if (a.name < b.name) {
              return -1; }
            return 0;
        });
        

        for(const {name, id} of arrOptions){
          const optionEl = document.createElement('option');
          const optionEl2 = document.createElement('option');
          optionEl.value = id;
          optionEl2.value = id;
          optionEl.textContent = name;
          optionEl2.textContent = name;
          fragment.append(optionEl);
          fragment2.append(optionEl2);
      } 


        if(chooseFirst){
                chooseFirst.replaceChildren(fragment);
                chooseFirst.insertAdjacentHTML('afterbegin', '<option selected> Choose stations </option>');
                chooseFirst.disabled = false;
                chooseFirst.style.fontSize = '20px';
            } 

        if(chooseSecond){
            chooseSecond.replaceChildren(fragment2);
            chooseSecond.insertAdjacentHTML('afterbegin', '<option selected> Choose stations </option>');
            chooseSecond.disabled = false;
            chooseSecond.style.fontSize = '20px';
        } 
    
    });
}


export { chooseFirst };
export { chooseSecond };
export  { fillSelectStation };

// console.log(chooseFirst);
 

 
 





