 
export function app(start, end, graphMetro ){
  

// 1 ////// вычисляем кратчайшее растояние до вершин от начальной точнки
    function shortPath(graph, start) {
        //объек с кратчайшими путями
        const costs = {};
        // массив проверенных узлов
        const processed = [];
        // соседние вершины рассматриваемого узла
        let neighbors = {};
        // определяем стартовую точку и ее значения, остальные заполняем "бесконечно большим числом"
        Object.keys(graph).forEach(node => {
            if (node !== start) {
                costs[node] = graph[start][node] || 100000000;
            }
        });
    // функция поиска наикратчайшего пути
        let node = findNodeLowestCost(costs, processed);
    // node - вершина с минимальной стоимостью , пока не есть не пройденные -  запускаме цикл
        while (node) {
            // console.log('node = ', node);
            // получаем стимость текущей вершины
            const cost = costs[node];
            // console.log('cost = ', cost);
            // получаем соседей текущей вершины
            neighbors = graph[node];
            // console.log(neighbors);
            // проходим циклам по соседям текущей вершины и перезписываем ее вес
            Object.keys(neighbors).forEach(neighbor => {
                let newCost = cost + neighbors[neighbor];
                // console.log('newCost = ', newCost);  
                // console.log('neighbors[neighbor] = ', neighbors[neighbor]);  
                if (newCost < costs[neighbor]) {
                    costs[neighbor] = newCost;
                    // console.log('перезапись веса = ', neighbor);
                }
                // console.log('neighbor = ', neighbor, '  newCost = ', newCost);
            });
            // console.log('costs = ', costs);
            node = findNodeLowestCost(costs, processed);
        }
        //  console.log(costs);
        return costs;
    }
 // 2 ///// функция поиска наикратчайшего пути и на выходе возращаем вершину с миимальной стоимостью
    function findNodeLowestCost(costs, processed) {
        let lowestCost = 100000000;
        let lowestNode;
        // console.log(costs);
        // console.log(processed); 
        // опрееяем вершину с наименьшим весом
        Object.keys(costs).forEach(node => {
            let cost = costs[node];
            // console.log('node = ', node, '   cost = ', cost,); 
            if ((cost < lowestCost) && !processed.includes(node)) {
            // console.log(lowestCost);    
                lowestCost = cost;
                lowestNode = node;            
            }
        });

        // пройденные вершины добавляем в массив
            processed.push(lowestNode);  
        // console.log('processed = ', processed);
        // console.log('lowestNode = ', lowestNode);
        return lowestNode;
    }
  // 3 ///// !!!!!!!!!!! начальные параметры
        // let start = '215';
        // console.log('start = ', start);
    // поиск наикротчайших точек от начала до всех остальных в ГРАФЕ!!!
    let shortPass;
    // console.log('start = ', start);
    // console.log('end = ', end);
    if (start != undefined){
  
        shortPass = shortPath(graphMetro, start);
        // console.log(shortPass);
    }
  // 4 //// поиск пути ////////////////////////// 
        let way = [];
  
        let middleNode;
        if (end != undefined){
        // пушим конечную точнку в массив
            way.push(end);
            // console.log('end = ', end);
            // console.log('ВЕС !!!  = ', shortPass[end]);


            // запускаем функцию поиска промежуточной точки от END точки посика
            middleNode = getBackWay(shortPass, end, graphMetro); 
            // запускаем до тех пор пока не найдем искомую точку
            while (middleNode && (start !== end)) {
                if(middleNode) {
                    way.push(middleNode);   
                }
            middleNode = getBackWay(shortPass, middleNode, graphMetro); 
            }
        }

        if (start != undefined){
            //добавляем начальную точку
            way.push(start);  
        }


        // функция возврата промежуточной  вершины
        function getBackWay(shortPass, end, graph){
            // вес конца пути
            let weightEnd = shortPass[end];
            // console.log(weightEnd);
            // описать ее соседей и их вес
            let neighborsEnd = graph[end];
            // console.log(neighborsEnd);
            // запускаем цикл по соседям и ищем дельту и вес
            let delta, tempNode;
            Object.keys(neighborsEnd).forEach(node => {
                delta = weightEnd - neighborsEnd[node];
                if(delta == shortPass[node]){
                    tempNode = node;
                    // console.log('true', node);        
                }
            });
            // добавляем в массив и возращаем исинную вершину
            return tempNode;
        }
 
        // console.log({'way': way, 'times': shortPass[end]});
        return  {'way': way, 
        'times': shortPass[end] == undefined ? 0 : shortPass[end], 
        'start': start, 
        'end': end} ;
        
}

