 // получаем массив координат, названий и ID
export async function getCoordStations(arrAllStations, arrWay = arrWay.way | []){
    //   console.log(arrAllStations); 
    //   console.log(arrWay); 
        arrWay = arrWay.way;
        let arrStationCommon =[];
        let asocArr={};
        // let arrGeoCoord =[];
        arrAllStations.then(res=>{
        // console.log(res); 
            res[0].map(elem =>{
                // console.log(elem); 
                elem.stations.map(item=>{
                    // console.log(item.id);
                    // arrStationCommon.push(item); 
                    if(arrWay.includes(item.id)) {
                        asocArr[item.id] = [[item.name, item.id], [item.lat, item.lng]];
                        // arrStationCommon.push([[item.name, item.id], [item.lat, item.lng]]); 
                        // arrStationCommon.push([[item.name, item.id], [item.lat, item.lng]]); 
                    }
                });
            } );
            return asocArr;
    })
    .then((asocArr)=>{
            arrWay.forEach(el => {
                arrStationCommon.push(asocArr[el]); 
                // console.log(el); 
            });
        // console.log(arrStationCommon); 
        return arrStationCommon;        
    });
    // console.log(arrStationCommon); 
    return arrStationCommon;    
};
    
 

