// полчаем элементы страницы
// const msgEl = document.getElementById('msg');
// let recognition = new window.SpeechRecognition(); 
import { eventCityVoices } from "./events";

export function speachRegognition(){
        
   // полчаем элементы страницы
// const msgEl = document.getElementById('msg');

// const randomNum = getRandomNumber();
// console.log('Number: ', randomNum);

window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;


let recognition = new window.SpeechRecognition();
console.log(recognition);
// Start recognition and Game
recognition.start();
recognition.lang = 'ru';
// recognition.lang = 'en';

// Capture user speak
function onSpeak(e){
//   console.log(e);
  const msg = e.results[0][0].transcript;
  // запускаем функцию из Events
  console.log(msg);
  eventCityVoices(msg);
 
}
  
// Speak result
recognition.addEventListener('result', onSpeak);


// End SR service
recognition.addEventListener('end', () =>{
  recognition.start();
});
 
    
}