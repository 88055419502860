let graphMetro = {};

 let Минск={};
 graphMetro.Минск = Минск;
 Минск['210'] = {'211': 2};
 Минск['211'] = {'210': 2, '212': 2};
 Минск['212'] = {'211': 2, '213': 2};
 Минск['213'] = {'212': 2, '214': 2};
 Минск['214'] = {'213': 2, '215': 2};
 Минск['215'] = {'214': 2, '216': 2};
 Минск['216'] = {'215': 2, '217': 2, '116': 6};
 Минск['217'] = {'216': 2, '218': 2};
 Минск['218'] = {'217': 2, '219': 2, '316': 12};
 Минск['219'] = {'218': 2, '220': 2};
 Минск['220'] = {'219': 2, '221': 2};
 Минск['221'] = {'220': 2, '222': 2};
 Минск['222'] = {'221': 2, '223': 2};
 Минск['223'] = {'222': 2};
 Минск['110'] = {'111': 2};
 Минск['111'] = {'110': 2, '112': 2};
 Минск['112'] = {'111': 2, '113': 2};
 Минск['113'] = {'112': 2, '114': 2};
 Минск['114'] = {'113': 2, '115': 2};
 Минск['115'] = {'114': 2, '116': 2, '314': 12};
 Минск['116'] = {'115': 2, '117': 2, '216': 6};
 Минск['117'] = {'116': 2, '118': 2};
 Минск['118'] = {'117': 2, '119': 2};
 Минск['119'] = {'118': 2, '120': 2};
 Минск['120'] = {'119': 2, '121': 2};
 Минск['121'] = {'120': 2, '122': 2};
 Минск['122'] = {'121': 2, '123': 2};
 Минск['123'] = {'122': 2, '124': 2};
 Минск['124'] = {'123': 2};
 Минск['313'] = {'314': 2};
 Минск['314'] = {'313': 2, '315': 2,'115' : 12};
 Минск['315'] = {'314': 2, '316': 2};
 Минск['316'] = {'315': 2, '218': 12}; 

 let Алматы={};
 graphMetro.Алматы = Алматы;
 Алматы['64.430'] = {'64.431': 2};
 Алматы['64.431'] = {'64.430': 2, '64.432': 2};
 Алматы['64.432'] = {'64.431': 2, '64.433': 2};
 Алматы['64.433'] = {'64.432': 2, '64.434': 2};
 Алматы['64.434'] = {'64.433': 2, '64.435': 2};
 Алматы['64.435'] = {'64.434': 2, '64.436': 2};
 Алматы['64.436'] = {'64.435': 2, '64.510': 2};
 Алматы['64.510'] = {'64.436': 2, '64.511': 2};
 Алматы['64.511'] = {'64.510': 2};

 let Самара={};
 graphMetro.Самара = Самара;
 Самара['54.509'] = {'54.307': 2};
 Самара['54.307'] = {'54.509': 2, '54.308': 2};
 Самара['54.308'] = {'54.307': 2, '54.309': 2}; 
 Самара['54.309'] = {'54.308': 2, '54.310': 2}; 
 Самара['54.310'] = {'54.309': 2, '54.311': 2}; 
 Самара['54.311'] = {'54.310': 2, '54.312': 2}; 
 Самара['54.312'] = {'54.311': 2, '54.313': 2}; 
 Самара['54.313'] = {'54.312': 2, '54.314': 2}; 
 Самара['54.314'] = {'54.313': 2, '54.315': 2}; 
 Самара['54.315'] = {'54.314': 2}; 

 let Днепр={};
 graphMetro.Днепр = Днепр;
 Днепр['58.367'] = {'58.368': 2};
 Днепр['58.368'] = {'58.367': 2, '58.369': 2};
 Днепр['58.369'] = {'58.368': 2, '58.370': 2};
 Днепр['58.370'] = {'58.369': 2, '58.371': 2};
 Днепр['58.371'] = {'58.370': 2, '58.372': 2};
 Днепр['58.372'] = {'58.371': 2};

 let Казань={};
 graphMetro.Казань = Казань;
 Казань['49.461'] = {'49.462': 2};
 Казань['49.462'] = {'49.461' : 2, '49.460': 2};
 Казань['49.460'] = {'49.462' : 2, '49.271': 2};
 Казань['49.271'] = {'49.460' : 2, '49.272': 2};
 Казань['49.272'] = {'49.271' : 2, '49.273': 2};
 Казань['49.273'] = {'49.272' : 2, '49.274': 2};
 Казань['49.274'] = {'49.273' : 2, '49.275': 2};
 Казань['49.275'] = {'49.274' : 2, '49.276': 2};
 Казань['49.276'] = {'49.275' : 2, '49.277': 2};
 Казань['49.277'] = {'49.276' : 2, '49.640': 2};
 Казань['49.640'] = {'49.277' : 2};


 let Новосибирск={};
 graphMetro.Новосибирск = Новосибирск;
 Новосибирск['52.294'] = {'52.295': 2};
 Новосибирск['52.295'] = {'52.294' : 2, '52.296': 2};
 Новосибирск['52.296'] = {'52.295' : 2, '52.297': 2, '53.303' : 2};
 Новосибирск['52.297'] = {'52.296' : 2, '52.298': 2};
 Новосибирск['52.298'] = {'52.297' : 2, '52.299': 2};
 Новосибирск['52.299'] = {'52.298' : 2, '52.300': 2};
 Новосибирск['52.300'] = {'52.299' : 2, '52.301': 2};
 Новосибирск['52.301'] = {'52.300' : 2};

 Новосибирск['53.302'] = {'53.303' : 2};
 Новосибирск['53.303'] = {'53.302' : 2, '53.304': 2, '52.296' : 2};
 Новосибирск['53.303'] = {'53.302' : 2, '53.304': 2};
 Новосибирск['53.304'] = {'53.303' : 2, '53.305': 2};
 Новосибирск['53.305'] = {'53.304' : 2, '53.306': 2};
 Новосибирск['53.306'] = {'53.305' : 2};
  

 let Екатеринбург={};
 graphMetro.Екатеринбург = Екатеринбург;
 Екатеринбург['48.261'] = {'48.262' : 2};
 Екатеринбург['48.262'] = {'48.261' : 2, '48.263': 2};
 Екатеринбург['48.263'] = {'48.262' : 2, '48.264': 2};
 Екатеринбург['48.264'] = {'48.263' : 2, '48.265': 2};
 Екатеринбург['48.265'] = {'48.264' : 2, '48.266': 2};
 Екатеринбург['48.266'] = {'48.265' : 2, '48.267': 2};
 Екатеринбург['48.267'] = {'48.266' : 2, '48.268': 2};
 Екатеринбург['48.268'] = {'48.267' : 2, '48.269': 2};
 Екатеринбург['48.269'] = {'48.268' : 2, '48.270': 2};
 Екатеринбург['48.270'] = {'48.269' : 2};
   

 let Нижний ={};
 graphMetro.Нижний = Нижний;
 Нижний['50.278'] = {'50.279' : 2};
 Нижний['50.279'] = {'50.278' : 2, '50.280' : 2, '51.289' : 2   };
 Нижний['50.280'] = {'50.279' : 2, '50.281' : 2};
 Нижний['50.281'] = {'50.280' : 2, '50.282' : 2};
 Нижний['50.282'] = {'50.281' : 2, '50.283' : 2};
 Нижний['50.283'] = {'50.282' : 2, '50.284' : 2};
 Нижний['50.284'] = {'50.283' : 2, '50.285' : 2};
 Нижний['50.285'] = {'50.284' : 2, '50.286' : 2};
 Нижний['50.286'] = {'50.285' : 2, '50.287' : 2};
 Нижний['50.287'] = {'50.286' : 2, '50.288' : 2};
 Нижний['50.288'] = {'50.287' : 2};

 Нижний['51.606'] = {'51.289' : 2};
 Нижний['51.289'] = {'51.606' : 2, '51.290' : 2, '50.279' : 2};
 Нижний['51.290'] = {'51.289' : 2, '51.291' : 2};
 Нижний['51.291'] = {'51.290' : 2, '51.292' : 2};
 Нижний['51.292'] = {'51.291' : 2};



 let Харьков ={};
 graphMetro.Харьков = Харьков;
 Харьков['59.373'] = {'59.374' : 2};
 Харьков['59.374'] = {'59.373' : 2, '59.375' : 2};
 Харьков['59.375'] = {'59.374' : 2, '59.376' : 2};
 Харьков['59.376'] = {'59.375' : 2, '59.377' : 2, '60.393' : 2};
 Харьков['59.377'] = {'59.376' : 2, '59.378' : 2};
 Харьков['59.378'] = {'59.377' : 2, '59.379' : 2, '61.401' : 2 };
 Харьков['59.379'] = {'59.378' : 2, '59.380' : 2};
 Харьков['59.380'] = {'59.379' : 2, '59.381' : 2};
 Харьков['59.381'] = {'59.380' : 2, '59.382' : 2};
 Харьков['59.382'] = {'59.381' : 2, '59.383' : 2};
 Харьков['59.383'] = {'59.382' : 2, '59.384' : 2};
 Харьков['59.384'] = {'59.383' : 2, '59.385' : 2};
 Харьков['59.385'] = {'59.384' : 2};
 
 Харьков['60.386'] = {'60.387' : 2};
 Харьков['60.387'] = {'60.386' : 2, '60.388' : 2};
 Харьков['60.388'] = {'60.387' : 2, '60.389' : 2};
 Харьков['60.389'] = {'60.388' : 2, '60.390' : 2};
 Харьков['60.390'] = {'60.389' : 2, '60.391' : 2};
 Харьков['60.391'] = {'60.390' : 2, '60.392' : 2};
 Харьков['60.392'] = {'60.391' : 2, '60.393' : 2, '61.398' : 2};
 Харьков['60.393'] = {'60.392' : 2,   '59.376' : 2};
 
 Харьков['61.393'] = {'61.394' : 2};
 Харьков['61.394'] = {'61.393' : 2, '61.395' : 2};
 Харьков['61.395'] = {'61.394' : 2, '61.396' : 2};
 Харьков['61.396'] = {'61.395' : 2, '61.397' : 2};
 Харьков['61.397'] = {'61.396' : 2, '61.398' : 2};
 Харьков['61.398'] = {'61.397' : 2, '61.399' : 2,  '60.392' : 2   };
 Харьков['61.399'] = {'61.398' : 2, '61.400' : 2};
 Харьков['61.400'] = {'61.399' : 2, '61.401' : 2};
 Харьков['61.401'] = {'61.400' : 2,  '59.378' : 2 };


 
 let Киев ={};
 graphMetro.Киев = Киев;
 Киев['55.316'] = {'55.317' : 2};
 Киев['55.317'] = {'55.316' : 2, '55.318' : 2};
 Киев['55.318'] = {'55.317' : 2, '55.319' : 2};
 Киев['55.319'] = {'55.318' : 2, '55.320' : 2};
 Киев['55.320'] = {'55.319' : 2, '55.321' : 2};
 Киев['55.321'] = {'55.320' : 2, '55.322' : 2};
 Киев['55.322'] = {'55.321' : 2, '55.323' : 2};
 Киев['55.323'] = {'55.322' : 2, '55.324' : 2};
 Киев['55.324'] = {'55.323' : 2, '55.325' : 2};
 Киев['55.325'] = {'55.324' : 2, '55.326' : 2,  '57.354' : 2           };
 Киев['55.326'] = {'55.325' : 2, '55.327' : 2, '56.341' : 2};
 Киев['55.327'] = {'55.326' : 2, '55.328' : 2};
 Киев['55.328'] = {'55.327' : 2, '55.329' : 2};
 Киев['55.329'] = {'55.328' : 2, '55.330' : 2};
 Киев['55.330'] = {'55.329' : 2, '55.331' : 2};
 Киев['55.331'] = {'55.330' : 2, '55.332' : 2};
 Киев['55.332'] = {'55.331' : 2, '55.333' : 2};
 Киев['55.333'] = {'55.332' : 2};
	 
 Киев['56.334'] = {'56.335' : 2};
 Киев['56.335'] = {'56.334' : 2, '56.336' : 2};
 Киев['56.336'] = {'56.335' : 2, '56.337' : 2};
 Киев['56.337'] = {'56.336' : 2, '56.338' : 2};
 Киев['56.338'] = {'56.337' : 2, '56.339' : 2};
 Киев['56.339'] = {'56.338' : 2, '56.340' : 2};
 Киев['56.340'] = {'56.339' : 2, '56.341' : 2};
 Киев['56.341'] = {'56.340' : 2, '56.342' : 2, '55.326' : 2};
 Киев['56.342'] = {'56.341' : 2, '56.343' : 2,   '57.355' : 2};
 Киев['56.343'] = {'56.342' : 2, '56.344' : 2};
 Киев['56.344'] = {'56.343' : 2, '56.345' : 2};
 Киев['56.345'] = {'56.344' : 2, '56.346' : 2};
 Киев['56.346'] = {'56.345' : 2, '56.347' : 2};
 Киев['56.347'] = {'56.346' : 2, '56.348' : 2};
 Киев['56.348'] = {'56.347' : 2, '56.349' : 2};
 Киев['56.349'] = {'56.348' : 2, '56.350' : 2};
 Киев['56.350'] = {'56.349' : 2, '56.506' : 2};
 Киев['56.506'] = {'56.350' : 2};


 Киев['57.351'] = {'57.352' : 2};
 Киев['57.352'] = {'57.351' : 2, '57.353' : 2};
 Киев['57.353'] = {'57.352' : 2, '57.354' : 2};
 Киев['57.354'] = {'57.353' : 2, '57.355' : 2, '55.325' : 2};
 Киев['57.355'] = {'57.354' : 2, '57.356' : 2, '56.342' : 2};
 Киев['57.356'] = {'57.355' : 2, '57.357' : 2};
 Киев['57.357'] = {'57.356' : 2, '57.358' : 2};
 Киев['57.358'] = {'57.357' : 2, '57.359' : 2};
 Киев['57.359'] = {'57.358' : 2, '57.360' : 2};
 Киев['57.360'] = {'57.359' : 2, '57.361' : 2};
 Киев['57.361'] = {'57.360' : 2, '57.362' : 2};
 Киев['57.362'] = {'57.361' : 2, '57.363' : 2};
 Киев['57.363'] = {'57.362' : 2, '57.364' : 2};
 Киев['57.364'] = {'57.363' : 2, '57.365' : 2};
 Киев['57.365'] = {'57.364' : 2, '57.366' : 2};
 Киев['57.366'] = {'57.365' : 2};
		 
	  
 
// console.log(graphMetro);
 


export { graphMetro };