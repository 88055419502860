export let arr = {};   
export var myMap;
import { drowingStation} from "./drowStation";
import { drowingLine } from "./drowLines";

 
export function loadMap(arrLines, coord) {
    ymaps.ready(function () {
    myMap = new ymaps.Map("map", {
            // center: [53.88, 27.561],
            center: coord,
            zoom: 11
        }, {
            searchControlProvider: 'yandex#search'
        });
    // получаем массив с линиями метро и пишем функцию в котором по каждой ветке выводим информацию.
    // запускаем функцию по умолчанию
    drowingStation(arrLines);
    //////////// СТРОИМ ЛИНИИ //////////////////////////////////////////////
    drowingLine(arrLines);
 
    // myMap.controls.remove('geolocationControl'); // удаляем геолокацию
    // myMap.controls.remove('searchControl'); // удаляем поиск
    // myMap.controls.remove('trafficControl'); // удаляем контроль трафика
    // myMap.controls.remove('typeSelector'); // удаляем тип
    // myMap.controls.remove('fullscreenControl'); // удаляем кнопку перехода в полноэкранный режим
    // myMap.controls.remove('zoomControl'); // удаляем контрол зуммирования
    // myMap.controls.remove('rulerControl'); // удаляем контрол правил
    // myMap.behaviors.disable(['scrollZoom']); // отключаем скролл карты (опционально)
    });
}   