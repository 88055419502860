import {getAllCyties} from './getAllCyties';

export let getCyties = getAllCyties();
export let chooseCyties = document.getElementById('chooseCyties') ;
// console.log(chooseCyties);

getCyties.then((dataCyties)=>{
    // console.info(dataCyties);
    const fragment = document.createDocumentFragment();
    for(const {id, name} of dataCyties){
        const optionEl = document.createElement('option');
        optionEl.value = id;
        optionEl.setAttribute('name', name);
        optionEl.textContent = name;
        fragment.append(optionEl);
    }

    if(chooseCyties){
        chooseCyties.replaceChildren(fragment);
        // chooseCyties.insertAdjacentHTML('afterbegin', '<option selected>  Choose Cities </option>');
        chooseCyties.disabled = false;
        chooseCyties.style.fontSize = '20px';
    } 
        (error) =>{
        console.log(error);
    };
 
});

  

