import { myMap } from "./loadMap";

//////////// СТРОИМ ЛИНИИ //////////////////////////////////////////////
export function drowingLine(arrLines=0, polyColor='#444', strokeWidth = 12){
    // console.log(arrLines);
    arrLines[0].map((item)=>{
        // console.log(item);
        let polyLine = item.stations;
        polyColor = `#${item.hex_color}`;
        // console.log(polyColor);
        let arrPoliLine=[];
        item.stations.map((elem)=>{
            arrPoliLine.push([elem.lat, elem.lng]);
        }),
        polyLine = new ymaps.Polyline(
        //  Указываем координаты вершин.  
        arrPoliLine,
        //arrLine, 
        {}, {
        // Задаем опции геообъекта.
        // Цвет с прозрачностью.
        strokeColor: polyColor,
        // Ширину линии.
        strokeWidth: strokeWidth,
        // strokeOpacity: 0.7
    });
  // добавляем инф в геообъект
        myMap.geoObjects.add(polyLine);
    });
}