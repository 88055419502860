  
export function setlocalStorage(arr){
    localStorage.setItem('metroArr', JSON.stringify(arr));
}
 

export function getlocalStorage(arr){
    return JSON.parse(localStorage.getItem('metroArr'));
}

export function removelocalStorage(){
    localStorage.removeItem("metroArr");
}    
 

export function deleteArr(obj){
    for (const prop of Object.getOwnPropertyNames(obj)) {
        delete obj[prop];
      }
}