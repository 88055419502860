// получаем INPUTS
import {chooseCyties} from './chooseCities';
import {chooseFirst} from './fillOptions';
import {chooseSecond} from './fillOptions';
// заполняем данные инпута станиями
import { fillSelectStation } from "./fillOptions";
// получаем данные для отрисовки карты 
import { getData } from './getData';  
 // переменная для удаления карты
import { myMap } from "./loadMap";
// логика расчета графов тут
import { app } from './app';
// отрисовка расчета времени
import { rendertime} from './renderTime'; 
import { getCoordStations } from './getCoordateStations';
import { arr } from "./loadMap";
import {graphMetro} from "./graphArr";
import { setlocalStorage, getlocalStorage, removelocalStorage, deleteArr } from './localStorage';
import { fillCheckedOption } from './selectedOption';

import { isDefined } from './isDefined';

import { drowRootLine } from './drowRootLine';

import { drowingStation} from "./drowStation";
import { drowingLine } from "./drowLines";
 
//задаем данные по умолчанию для вывода г. Минск

// если есть в Local Storag, то данные берем от туда
export let nameCity, arrGraph, getStations;
    let cityMap = document.querySelector('.cityMap');

export function init(){
        deleteArr(arr);
        // console.log(arr);
        // arr={};
        let nameCity = 'Минск';
        let arrGraph = graphMetro['Минск'];
        arr.nameCity = nameCity;
        arr.arrGraph = arrGraph;
        arr.idCity = 1002;
        arr.index = 9;
       
        // setlocalStorage(arr);
        // запускаем начальную страницу если нет сохраненных данных в локал сторажд

        getStations = getData(arr.idCity); 
        // заполняем селекты стнациями
        fillSelectStation(getStations).then(()=>{
            fillCheckedOption (arr);
            document.getElementById('chooseCyties').options[9].selected = true;     
        });
 
        cityMap.innerHTML= arr.nameCity;
        rendertime();
        // console.log(arr);
}


let getLS = getlocalStorage();
// console.log(getLS);
 

if(!isDefined(getLS)) {
    init();
    // console.log('init function');
} else{
    // выводим из локал сторадж сохраненные данные
    for (let key in getLS) {
        arr[key] = getLS[key];
    }

    let ind = arr.index;

   

// выводим время в пути если есть в локал сторадже
    if(arr.way !=null && arr.getCoord != null ){
        rendertime(arr.way, arr.getCoord);  
    }
 
    getStations = getData(arr.idCity); 
    // заполняем селекты стнациями
    fillSelectStation(getStations).then(()=>{
         fillCheckedOption (arr);
          document.getElementById('chooseCyties').options[ind].selected = true;
    });
}
 
// console.log(arr);

// // выводим название города на карту
cityMap.innerHTML= arr.nameCity;
 

function eventsCity(){
    chooseCyties.addEventListener('change', ()=>{
        // удаляем предыдущую карту для отриссовки новой 
        myMap.destroy();
        // выводим index и выводим имя выбранногг города на стр
        let index = chooseCyties.selectedIndex;      
        // console.log(index);
        cityMap.innerHTML= chooseCyties[index].innerText;
        let idCity = +chooseCyties.value;
        // console.log(chooseCyties[index].getAttribute("name"));
        // получаем имя города и меняем веса в граффе
        nameCity = chooseCyties[index].getAttribute("name").split(' ', 1).join('');
        arrGraph = graphMetro[nameCity];

        for (let key in arr) {
            arr[key] = null;
        }

        arr.nameCity = nameCity;
        arr.idCity = idCity;
        arr.index = index;
        arr.arrGraph = arrGraph;
        console.log(arr);
        setlocalStorage(arr);
  
        getStations = getData(arr.idCity); 
        // заполняем селекты стнациями
        fillSelectStation(getStations);

        document.getElementById('resultOutput').innerHTML = `Расчетное время в пути составляет`;
    });
 
}

/// выбираем по голосу - экспортируем ее в Speachrecognitions
function eventCityVoices(result){
    // console.log(result);
    // console.log(chooseCyties.options);
    // тоже самое со спред-оператором
    const arrOptions = [...chooseCyties.options];
    // console.log(arrOptions);
    arrOptions.map((element, ind) => {
        // console.log(element);
        if(result == element.getAttribute("name")) {
            console.log(element.getAttribute("name"));
            // console.log(element.value);
            // console.log(ind);
            // удаляем предыдущую карту для отриссовки новой 
            myMap.destroy();
            // выводим index и выводим имя выбранногг города на стр
            let index = ind;      
            document.getElementById('chooseCyties').options[index].selected = true;
            // console.log(index);
            cityMap.innerHTML= element.getAttribute("name");
            let idCity = +element.value;
            // console.log(chooseCyties[index].getAttribute("name"));
            // получаем имя города и меняем веса в граффе
            nameCity = element.getAttribute("name").split(' ', 1).join('');
            arrGraph = graphMetro[nameCity];

            for (let key in arr) {
                arr[key] = null;
            }
            arr.nameCity = nameCity;
            arr.idCity = idCity;
            arr.index = index;
            arr.arrGraph = arrGraph;
            console.log(arr);
            setlocalStorage(arr);
            getStations = getData(arr.idCity); 
            // заполняем селекты стнациями
            fillSelectStation(getStations);
            document.getElementById('resultOutput').innerHTML = `Расчетное время в пути составляет`;
        }
    });

}
 
let start, end;

function eventStationFrst(){
    chooseFirst.addEventListener('input', ()=>{
        // console.log(chooseFirst.value);
        start = chooseFirst.value;
        arr.start = start;
        if(arr.end != undefined){
            let way = app(arr.start, arr.end, arr.arrGraph);
            // console.log(start);
            // console.log(way);
            // получаем массив с координатами пути
            let getCoord = getCoordStations(getStations, way); 
            // console.log(getCoord);

         
            // пишем на странице затраченное время
            rendertime(way, getCoord);
            // далее необходима отрисвка его на карте
             myMap.geoObjects.removeAll();

            getStations.then((res)=>{
                    // console.log(res);
                    drowingStation(res);
                    drowingLine(res);
            });
            getCoord.then((res)=>{
                drowRootLine(res);
            });

            arr.way = way;
            setlocalStorage(arr);
            // console.log(arr);
        }

    });
}

function eventStationSec(){
    chooseSecond.addEventListener('input', ()=>{
        // console.log(chooseSecond.value);
        end = chooseSecond.value;
        arr.end = end;
        // прописать функцию из отдельного модуля проверки на наоль и не сивло
        if(arr.start != undefined){
            let way = app(arr.start, arr.end, arr.arrGraph);
            // console.log(end);
            // console.log(way);
            // получаем массив с координатами пути
            let getCoord = getCoordStations(getStations, way); 
            // console.log(way);
            // пишем на странице затраченное время
            rendertime(way, getCoord);
            // далее необходима отрисвка его на карте
            myMap.geoObjects.removeAll();

            getStations.then((res)=>{
                    // console.log(res);
                    drowingStation(res);
                    drowingLine(res);
            });
            getCoord.then((res)=>{
                drowRootLine(res);
            });
 
            arr.way = way;
            setlocalStorage(arr);
            // console.log(arr);
        }
    });
}
 

export default eventsCity;
export {eventStationFrst};
export {eventStationSec};
export {eventCityVoices};

 
 