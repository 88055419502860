 
// import {fillSelectStation} from './modules/fillOptions';
  
// import {graphMetro} from "./modules/graphArr";

//////// собития по выбору города и станций
import eventsCity from './modules/events';
eventsCity();


import { eventStationFrst } from "./modules/events";
eventStationFrst();
import { eventStationSec } from "./modules/events";
eventStationSec();

import { btnReset } from './modules/reset';

import { speachVoices, setTextMessage,  speakText} from './modules/speachreader';
speachVoices();

import { speachRegognition } from './modules/speachRegognition';
speachRegognition();
 
import "../style.css";

  