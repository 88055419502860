// получаем данные пользователся из формы и записываем их в массив

  
import jsonPath from '../../stationsAll.json';
  
export async function getAllCyties(){
    // const res = await fetch('./stationsAll.json', {
        const res = await fetch(jsonPath, {
        // const res = await fetch('https://api.hh.ru/metro/', {
        });
    const data = await res.json();
 // console.log( data );
// получаем массив из линий метрополитена города
    let cyties = [...data]
    .map(  function (items) {
            if( items.name &&  items.id){
                return {
                   id: items.id,
                   name: items.name
                };
            }    
    });
    // console.log(cyties);
    return cyties;    
}