
import { init } from './events';
import { removelocalStorage } from './localStorage';
 // переменная для удаления карты
 import { myMap } from "./loadMap";

//  const form = document.getElementById('undeground');
//  console.log(form);
 
//  console.log(document.getElementById('chooseCyties').options[0].selected);

export const btnReset = document.querySelector('#reset');
// console.log(btnReset);

btnReset.addEventListener('click', (event)=>{
    event.preventDefault();
    document.getElementById('chooseCyties').options[9].selected = true;
    removelocalStorage();
    myMap.destroy();
    init();
    
});