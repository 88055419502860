//функция для отображения времени перемещения от нач до конечн станции метро
import {arr} from './loadMap';
import { setlocalStorage } from './localStorage';

import { isDefined } from './isDefined';
import { setTextMessage,  speakText } from './speachreader';
import { smsSend } from './sms'; 
 
export async function rendertime(array, getCoord ){ 
    const result = document.getElementById('resultOutput');

    if (!isDefined(array) | !isDefined(getCoord)) {
        result.innerHTML = `Время в пути от станции: `;
    }
    else{
        //  console.log(result);
        // arr содержит начало, конец  маршрута и ID промежуточных станций
        // getCoord значения каждой станции метро - координаты. ID и name
        let arrAsync = await array;
        if (arrAsync.times !== undefined){
            let temp = await getCoord;
        arr.getCoord = temp;
        setlocalStorage(arr);
        //  console.log(arr);
            // console.log(temp);
            let text = `Время в пути от станции "${temp[0][0][0]}" до станции "${temp[temp.length-1][0][0]}" составляет: ${arrAsync.times} минут`;
            result.innerHTML = text;
            console.log(text);

            setTextMessage(text);
            speakText();

            smsSend(text);
        }       
    }
 
}



