import { myMap } from "./loadMap";
import { drowingStation} from "./drowStation";
import { drowingLine } from "./drowLines";

import { getStations } from "./events";

//////////// СТРОИМ ЛИНИИ //////////////////////////////////////////////
export async function drowRootLine(arrInput){
  
        // console.log(polyColor);

        let arr = await arrInput;

        let arrPoliLine=[];
       
        arr.map((elem)=>{
            // console.log(elem);
            arrPoliLine.push([elem[1][0], elem[1][1]]);
        });

        // console.log(arrPoliLine);


        let newPolyLine = new ymaps.Polyline( 
           
        //  Указываем координаты вершин.  
        arrPoliLine, {},
        {
            // Задаем опции геообъекта.
            // Отключаем кнопку закрытия балуна.
            // balloonCloseButton: false,
            // Цвет линии.
            // strokeColor: "#FF4500",
            strokeColor: "#FF00FF",
             
            // Ширина линии.
            strokeWidth: 26,
            // Коэффициент прозрачности.
            // strokeOpacity: 0.7
        }
    );

 
    // myMap.geoObjects.removeAll();
 
  
    myMap.geoObjects.add(newPolyLine);
 

}


 