export function fillCheckedOption (arr){
    // console.log(arr.end, arr.start);
    if (arr.start != undefined) {
        let currentSelect = document.querySelectorAll('select[name="chooseFirst"] option') ;
        // console.log(currentSelect);
        currentSelect.forEach((item)=>{
            // console.log(item);
            if(item.value == arr.start) {
                item.selected = true;
            }
        });
    }
    if (arr.end != undefined) {
        let current2Select = document.querySelectorAll('select[name="chooseSecond"] option') ;
        current2Select.forEach((item)=>{
            if(item.value == arr.end) {
                item.selected = true;
            }
        });
    }
}


 

  

// выделить веса в графиах в отдельный файд = массив, сделать его для харькова и 2-3 городов рабочим

// поколдавть с селектами - сделать возможность выбора по поиску

// верификация пользователя по смс

// сохранить данные в LOCAL STORAGE

// переписать стилии с SAAS