 const voicesSelect = document.getElementById('voices');
  // Store Voices
 let voices = [];
// init speech synth
export const message = new SpeechSynthesisUtterance();


export function speachVoices(){
  // const textArea = document.getElementById('text');
  // const readBtn = document.getElementById('read');
 
  let option;
  function getVoices(){
    voices = speechSynthesis.getVoices();
      voices.forEach(voice => {
       option = document.createElement('option');
      option.value = voice.name;
      option.innerText = `${voice.name} ${voice.lang}`;
      voicesSelect.appendChild(option);
    });
   
    // document.getElementById('voices').options[20].selected = true;
  }

   

    // setVoices
    function setVoice(e){
      message.voice = voices.find( voice => voice.name === e.target.value );
    }
    // Voices changed
    speechSynthesis.addEventListener('voiceschanged', getVoices);
    // change voices
    voicesSelect.addEventListener('change', setVoice);
   
  

}
 
  // // set text
  export function setTextMessage(textMessages) {
    message.text = textMessages;
  }
  // Speak text
  export function speakText(){
    speechSynthesis.speak(message);
  }

 
 


