

export function smsSend(text){


    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "Basic cmVudC5tdHMuYnlfYk5OSzp1V1lUeFI=");

var raw = JSON.stringify({"phone_number":375292502675,"channels":["sms"],"channel_options":{"sms":{"text":text,"alpha_name":"rent.mts.by","ttl":360}}});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch('https://api.br.mts.by/8466/json2/simple', requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));

}

// smsSend();