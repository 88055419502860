import { arr } from "./loadMap";
import { app } from "./app";
import { myMap } from "./loadMap";
import { fillCheckedOption } from './selectedOption';
// получаем массив  getStations
import { getStations as getStation } from './events';
// import { getData } from './getData';  
// получаем массив координта пути для отрисовки маршрута  
import { getCoordStations } from './getCoordateStations';
// отрисовка расчета времени
import { rendertime} from './renderTime'; 

import {arrGraph} from "./events";

import { setlocalStorage } from './localStorage';

import { drowRootLine } from './drowRootLine';
import { drowingStation} from "./drowStation";
import { drowingLine } from "./drowLines";



// console.log(arr);
// let wwww = getStations;
// console.log(wwww);
 let flag = false;
export function eventMap(nameLine){
      nameLine.events.add('click', function (event) { 
          // console.log (event.get('type'));
          // console.log(event.get('target').properties);
          // console.log(event.get('target').properties._data.iconContent);
          // console.log(arr);

          if (flag == false) {
               delete arr.end;
               arr.start = event.get('target').properties._data.iconContent;
               flag = true;
               // console.log(arr.start, flag);   
               fillCheckedOption (arr);
               if(arr.end != undefined){
                    let way = app(arr.start, arr.end, arr.arrGraph);
                    // console.log(way);
                    
                    // получаем массив с координатами пути
                    let getCoord = getCoordStations(getStation, way); 
                    // console.log(way);
                    // пишем на странице затраченное время
                    rendertime(way, getCoord);
                    // далее необходима отрисвка его на карте
                    myMap.geoObjects.removeAll();

                    getStation.then((res)=>{
                              console.log(res);
                              drowingStation(res);
                              drowingLine(res);
                    });
                    getCoord.then((res)=>{
                         drowRootLine(res);
                    });     
 
 
                    arr.way = way;
                    setlocalStorage(arr);
                    // console.log(arr);
                }
          } else{
               arr.end = event.get('target').properties._data.iconContent;
               flag = false;
               // console.log(arr.end, flag);
               fillCheckedOption (arr);
               if(arr.start != undefined){
                    let way = app(arr.start, arr.end, arr.arrGraph);
                    // console.log(way);
                    
                    // получаем массив с координатами пути
                    let getCoord = getCoordStations(getStation, way); 
                    // console.log(way);
                    // пишем на странице затраченное время
                    rendertime(way, getCoord);
                    // далее необходима отрисвка его на карте
                    myMap.geoObjects.removeAll();

                    getStation.then((res)=>{
                              console.log(res);
                              drowingStation(res);
                              drowingLine(res);
                    });
                    getCoord.then((res)=>{
                         drowRootLine(res);
                    });
 
                    arr.way = way;
                    setlocalStorage(arr);
                    // console.log(arr);
                }
          } 
     });
}


export function eventMouse(nameLine){
     nameLine.events.add('mouseenter', function (event) { 
     //     console.log (event.get('type'));
     //     console.log(event.get('target').properties._data.iconContent);
     //     console.log(event.get('target').properties._data.balloonContent);
    });
}

