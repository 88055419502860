import {arr, myMap} from "./loadMap";
import { eventMap, eventMouse } from "./eventMap";
// import { app } from "./app";

// let way = app();


// let wayCoord = [];

// получаем массив с линиями метро и пишем функцию в котором по каждой ветке выводим информацию.
export function drowingStation(arrLines){
    //  выводим новые коллекции Круглый значки (берем из ) 
arrLines[0].map((item)=>{
    // console.log(item);
    // console.log(item.stations);
    // console.log(item.id);
    let nameLine = item.stations;
    nameLine = new ymaps.GeoObjectCollection(null, {
        preset: 'islands#blueCircleDotIcon',
        // description: "при открытии «Гауэр-стрит»",
        // iconCaption: "Юстон-сквер",
        iconColor:  `#${item.hex_color}`,
        // iconLayout: 'default#pieChart',
        // Радиус диаграммы.
        iconPieChartRadius: 35,
        // Радиус центрального сектора диаграммы.
        iconPieChartCoreRadius: 15,
        // скрытия балуна
        hideIconOnBalloonOpen: false
    });    

    // добавляем метки на карту
    item.stations.map((elem)=>{
 
        nameLine.add(new ymaps.Placemark([elem.lat, elem.lng], {
            // Подпись к диаграмме.
            // iconCaption: `${elem.id}`,
            // Оставляем центр диаграммы пустым.
            iconContent: `${elem.id}`,
            // balloonContentHeader: 'Худап балуна',
            // balloonContentBody: 'Тела балуна',
            // balloonContentFooter: 'Подвал балуна'
            // balloonContent: ` 
            // <div class="balloon">
            //     <div class="balloon__adress"> Ст. метро:  ${elem.name}</div> 
            // </div>`
            // balloonContent: `${elem.id}`,
            hintContent: `${elem.name}`

            }));
    });

    //////////// СОБЫТИЯ НА КАРТУ //////////////////////////////////////////
    eventMap(nameLine);
    eventMouse(nameLine);
  
    // добавляем инф в геообъект
    myMap.geoObjects.add(nameLine);
});
}